import { IonButton, IonContent, IonGrid, IonIcon, IonInput, IonLabel, IonModal, IonRow, IonSpinner, IonToast } from '@ionic/react'
import { useContext, useEffect, useState } from 'react'
import StandardContainer from '../../commonComponents/StandardContainer'
import { AuthContext } from '../../auth'
import GetLocationDataBasedOnLocationId from '../../Utils/GetLocationDataBasedOnLocationId'
import axios from 'axios'
import { limitDecimalPlaces } from '../../utils'
import styles from '../NewCalendarView/Styles/NoShowButtonStyles' // Import the styles
import CancelFeeModal from '../../commonComponents/CancelFeeModal'
import { cashOutline, createOutline, removeCircleOutline } from 'ionicons/icons'
import { colors } from '../../theme/colors'
import { fontSizes, fontWeights } from '../../theme/typography'

export default function NoShowButton({ appointment, getAppointment }) {
  const [chargesModalOpen, setChargesModalOpen] = useState(false)
  const { locationId, locationData } = useContext(AuthContext)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [cardOnFile, setCardOnFile] = useState(false)
  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
  const isCustomerSubscribed = appointment?.customChargeValue?.isSubscribed
  const locationNoShowSettings = isCustomerSubscribed
    ? selectedLocationData?.appointmentSettings?.noShowSettings?.subscriber
    : selectedLocationData?.appointmentSettings?.noShowSettings?.nonSubscriber
  const currentTime = new Date().getTime()
  const timeDifferenceInHours = (appointment?.startTime - currentTime) / (60 * 60 * 1000)
  const cancellationAllowed = timeDifferenceInHours > (locationNoShowSettings?.hoursBefore || 24)
  const [selectedOption, setSelectedOption] = useState('standard')
  const [customCharge, setCustomCharge] = useState('')
  const [toast, setToast] = useState({
    isOpen: false,
    message: '',
    color: 'success', // default color
  })
  const handleOptionChange = (value) => {
    setSelectedOption(value)
    setCustomCharge('')
    setError('')
  }

  const handleCustomChargeChange = (e) => {
    setCustomCharge(e.detail.value)
    if (e.detail.value > appointment?.payment?.totalPrice) {
      setError('Charge value cannot be more than appointment total price')
    } else {
      setError('')
    }
  }
  const getCancellationCharges = () => {
    // Assuming appointment.payment.totalprice is the total price
    let totalPrice = appointment?.payment?.totalPrice
    let defaultCancellationCharges = 0
    // Check the type property and apply discount accordingly
    if (locationNoShowSettings?.type === 'fixed') {
      // Subtract the fixed value
      defaultCancellationCharges = locationNoShowSettings?.value
    } else if (locationNoShowSettings?.type === 'percentage') {
      // Subtract the percentage
      const percentage = locationNoShowSettings?.value / 100
      defaultCancellationCharges = totalPrice * percentage
    }
    return defaultCancellationCharges
  }
  const defaultCancellationCharges = getCancellationCharges()

  const openChargesModal = () => {
    setChargesModalOpen(true)
  }
  const closeChargesModal = () => {
    setChargesModalOpen(false)
    setSelectedOption('standard')
    setCustomCharge('')
    setError('')
  }

  const handleChargeAndUpdateStatus = async () => {
    setLoading(true)

    // Determine the no show charge based on selected option and settings
    const getNoShowCharge = () => {
      if (!locationNoShowSettings?.charge || cancellationAllowed || !cardOnFile) return { noShowCharge: 0, customized: false }

      switch (selectedOption) {
        case 'standard':
          return { noShowCharge: parseFloat(defaultCancellationCharges), customized: false }
        case 'waive':
          return { noShowCharge: 0, customized: true }
        default:
          return { noShowCharge: parseFloat(customCharge), customized: true }
      }
    }

    const { noShowCharge, customized: customizedNoShowCharge } = getNoShowCharge()

    try {
      const input = {
        appointmentId: appointment?._id,
        status: 'No Show',
        paidExternal: true,
        noShowCharge,
        customizedNoShowCharge,
      }

      // Update appointment status
      await axios.patch(`/appointment_v2/updateStatus?id=${appointment?._id}`, input)

      // Update appointment with no show charge
      const paymentObjectWithNoShowCharge = {
        ...appointment?.payment,
        noShowCharge,
      }
      await axios.patch(`/appointment_v2?id=${appointment?._id}`, { payment: paymentObjectWithNoShowCharge })

      await getAppointment()

      // Display success toast message
      setToast({
        isOpen: true,
        message: 'No show charge updated successfully!',
        color: 'success',
      })
    } catch (error) {
      // Display error toast message
      setError('Failed to update no show charge.')
      setToast({
        isOpen: true,
        message: 'Error updating no show charge.',
        color: 'danger',
      })
    } finally {
      setLoading(false)
    }
  }
  const getCard = async () => {
    try {
      let response = await axios.get(`/customer_v2/cardStripe?customerId=${appointment?.customer?._id}`)

      setCardOnFile(response?.data?.cardOnFile || false)
    } catch (error) {
      console.error('Error fetching card information:', error)
    }
  }
  useEffect(() => {
    getCard()
  }, [])
  useEffect(() => {
    let formattedValue = limitDecimalPlaces(customCharge, 2)
    setCustomCharge(formattedValue)
  }, [customCharge])
  const options = [
    {
      value: 'standard',
      label: `Charge $${defaultCancellationCharges} fee`,
      description: `${appointment?.customer?.firstName} ${appointment?.customer?.lastName} will be charged a cancellation fee of `,
      amount: `$${defaultCancellationCharges}`,
      icon: cashOutline,
    },
    {
      value: 'custom',
      label: 'Custom Fee',
      description: `${appointment?.customer?.firstName} ${appointment?.customer?.lastName} will be charged an amount you choose.`,
      customInput: true,
      icon: createOutline,
    },
    {
      value: 'waive',
      label: 'Waive Fee',
      description: `${appointment?.customer?.firstName} ${appointment?.customer?.lastName} will not be charged for missing the appointment.`,
      icon: removeCircleOutline,
    },
  ]
  return (
    <>
      <IonButton color={'dark'} shape='round' onClick={openChargesModal} style={styles.button} fill='solid'>
        No Show
      </IonButton>
      <IonModal isOpen={chargesModalOpen} onDidDismiss={closeChargesModal}>
        {/* <CommonHeader closeButton={true} closeModal={closeChargesModal} title={'No Show'}></CommonHeader> */}
        {locationNoShowSettings?.charge && !cancellationAllowed && cardOnFile ? (
          <>
            <IonContent className='regularBackground'>
              <StandardContainer padding={20}>
                <div style={{ marginTop: '2rem', padding: '1rem', display: 'flex', flexDirection: 'column' }}>
                  <IonLabel style={{ fontSize: fontSizes.size25, marginBottom: 20, fontWeight: fontWeights.weight600 }}>Mark as No Show</IonLabel>
                  <IonLabel style={{ fontWeight: fontWeights.weight600 }}>
                    Confirm that the customer did not show up to their appointment which carries a fee of ${defaultCancellationCharges}
                  </IonLabel>
                  <IonGrid style={{ width: '100%', marginTop: 30 }}>
                    {options.map((option) => {
                      return (
                        <IonRow
                          onClick={() => handleOptionChange(option.value)}
                          key={option.value}
                          style={{
                            padding: 10,
                            marginBottom: 10,
                            boxShadow: selectedOption == option.value ? 'rgb(0 0 0 / 13%) 0px 5px 15px' : null,
                            border: `2px solid ${selectedOption == option.value ? colors.black60 : colors.black10}`,
                            borderRadius: 5,
                            display: 'Flex',
                            alignItems: 'center',
                          }}
                        >
                          <IonLabel style={{ display: 'flex', justifyContent: 'cente', alignItems: 'center' }}>
                            <IonIcon style={{ marginRight: 10 }} icon={option.icon} /> {option.label}
                          </IonLabel>
                          {option.customInput && selectedOption === 'custom' && (
                            <>
                              <IonInput type='number' value={customCharge} onIonInput={handleCustomChargeChange} placeholder='0' inputmode='decimal' />
                              {error && <IonLabel style={styles.errorLabel}>{error}</IonLabel>}
                            </>
                          )}
                        </IonRow>
                      )
                    })}
                  </IonGrid>
                  <IonButton
                    color={'dark'}
                    style={{ width: '100%', marginTop: 30 }}
                    disabled={
                      loading ||
                      (locationNoShowSettings?.charge
                        ? (selectedOption !== 'standard' && selectedOption !== 'waive' && customCharge === '') || error !== ''
                        : false)
                    }
                    onClick={handleChargeAndUpdateStatus}
                  >
                    {loading && <IonSpinner style={{ marginRight: '0.5rem' }} name='lines' />}
                    {selectedOption === 'standard' && `Confirm & Charge $${defaultCancellationCharges}`}
                    {selectedOption === 'custom' && `Confirm & Charge $${customCharge}`}
                    {selectedOption === 'waive' && 'Confirm No Show'}
                  </IonButton>
                  <IonButton onClick={closeChargesModal} fill='outline' color={'dark'} style={{ width: '100%' }}>
                    Close
                  </IonButton>
                </div>
              </StandardContainer>
            </IonContent>
          </>
        ) : (
          <CancelFeeModal
            title={'Mark as No Show'}
            message='Confirm that the customer didnot show up to their appointment'
            cancel={closeChargesModal}
            loading={loading}
            save={handleChargeAndUpdateStatus}
            saveText='Confirm No Show'
            cancelText='Close'
          />
        )}
      </IonModal>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        message={toast.message}
        color={toast.color}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        duration={2000}
      />
    </>
  )
}
